import { FieldOperator } from '../../components/documentation/ApplicabilityFilter/utils';
import { ApplicabilityValueElement } from '../../interfaces/documentation';
import { JobType, TypeOfPart } from '../../utils/constants/royalties';

export function getJobTitle(): string {
  const date = new Date(); // Get the current date
  const year = date.getFullYear(); // Get the current year

  // Get the current month (from 0 to 11)
  const month = date.getMonth();

  // Determine the quarter (Q1: January-March, Q2: April-June, Q3: July-September, Q4: October-December)
  let quarter: string;
  if (month >= 0 && month <= 2) {
    quarter = 'Q1';
  } else if (month >= 3 && month <= 5) {
    quarter = 'Q2';
  } else if (month >= 6 && month <= 8) {
    quarter = 'Q3';
  } else {
    quarter = 'Q4';
  }

  // Generate the title
  return `Create a Job - ${quarter} ${year}`;
}

export interface SpecialConnection {
  connectionType: string;
  nbPin: number | null;
  nbBox: number | null;
}

// Define the TypeScript type for each part type
export interface PartType {
  type: string;
  nbSides: number | null;
  nbPin: number | null;
  nbBox: number | null;
  specialConnections?: SpecialConnection[];
}

// Create an array of part types based on the simplified data
export const partTypes: PartType[] = [
  {
    type: TypeOfPart.LIFTING_HANDLING_EQUIPMENT,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.WATERBUSHING_CIRCULATING_HEAD,
    nbSides: 1,
    nbPin: 1,
    nbBox: 0,
  },
  {
    type: TypeOfPart.TEST_CAP_PLUG,
    nbSides: 1,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.CROSSOVER,
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.PUP_JOINT,
    nbSides: 1,
    nbPin: null,
    nbBox: null,
    specialConnections: [
      {
        connectionType: 'T&C',
        nbPin: 2,
        nbBox: 2,
      },
      {
        connectionType: 'FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
      {
        connectionType: 'SEMI-FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
    ],
  },
  {
    type: TypeOfPart.NIPPLE,
    nbSides: 1,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: TypeOfPart.COUPLING,
    nbSides: 1,
    nbPin: 0,
    nbBox: 2,
  },
  {
    type: TypeOfPart.FLOAT_SHOE,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.FLOAT_COLLAR,
    nbSides: 2,
    nbPin: 1,
    nbBox: 1,
  },
  {
    type: TypeOfPart.CASING_TUBING_HANGER,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.FLOW_COUPLING_BLAST_JOINT,
    nbSides: 2,
    nbPin: 1,
    nbBox: 1,
  },
  {
    type: TypeOfPart.THREE_WAY_CROSSOVER_Y_TOOL,
    nbSides: 3,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.BULL_PLUG_MULE_SHOE_REENTRY_GUIDE,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.LANDING_NIPPLE_NO_GO_LOCATOR,
    nbSides: 1,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: TypeOfPart.SAFETY_VALVE,
    nbSides: 2,
    nbPin: 2,
    nbBox: 0,
  },
  {
    type: TypeOfPart.ECCENTRIC_COMPLETION_EQUIPMENT,
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.PACKER,
    nbSides: 2,
    nbPin: null,
    nbBox: null,
  },
  {
    type: TypeOfPart.LINER_HANGER,
    nbSides: 1,
    nbPin: 0,
    nbBox: 1,
  },
  {
    type: TypeOfPart.FULL_LENGTH_BASE_PIPE,
    nbSides: 1,
    nbPin: null,
    nbBox: null,
    specialConnections: [
      {
        connectionType: 'T&C',
        nbPin: 2,
        nbBox: 2,
      },
      {
        connectionType: 'FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
      {
        connectionType: 'SEMI-FLUSH',
        nbPin: 1,
        nbBox: 1,
      },
    ],
  },
  {
    type: TypeOfPart.OTHER,
    nbSides: null,
    nbPin: null,
    nbBox: null,
  },
];

export const handleFilterChange = (
  filters: ApplicabilityValueElement[],
  setFilters: React.SetStateAction<any>,
  controlValue: any,
  field: string
) => {
  // Check if the filter already exists
  const existingFilterIndex = filters.findIndex((filter) => filter.field === field);

  // If the selected value is empty, remove the existing filter if it exists
  if (controlValue === '' || controlValue === null || controlValue === undefined) {
    if (existingFilterIndex !== -1) {
      const updatedFilters = [...filters];
      updatedFilters.splice(existingFilterIndex, 1); // Remove the filter
      setFilters(updatedFilters);
    } else {
      setFilters(filters); // No changes needed if the filter doesn't exist
    }
    return;
  }

  // If the filter exists, update its value
  if (existingFilterIndex !== -1) {
    const updatedFilters = [...filters];
    updatedFilters[existingFilterIndex].value = controlValue;
    setFilters(updatedFilters);
  } else {
    // If the filter does not exist, add it to the filters array
    setFilters([...filters, { field, operator: FieldOperator.EQUAL, value: controlValue }]);
  }
};

// Create a default side configuration
export const createNewSide = () => ({
  end: '',
  connection: '',
  od_inch: '',
  weight: '' as any,
  application: '',
  special_bevel: false,
  oriented_connection: false,
});

// Create sides from part type
export const createSidesFromPartType = (partType: PartType) => {
  const sides = [];
  for (let i = 0; i < Math.max(partType.nbSides, 1); i++) {
    const end = determineEndType(partType, i);
    sides.push({
      ...createNewSide(),
      end,
    });
  }
  return sides;
};

// Determine end from part type value
export const determineEndType = (partType: PartType, index: number) => {
  if (partType.nbPin !== null && partType.nbPin > 0 && index < partType.nbPin) {
    return 'PIN';
  }
  if (
    partType.nbBox !== null &&
    partType.nbBox > 0 &&
    index < partType.nbBox + (partType.nbPin || 0)
  ) {
    return 'BOX';
  }
  // If nbPin and nbBox are null, let the user choose
  return '';
};

export const addDefaultSides = (jobType: string, typeOfPart: string, replace: Function) => {
  if (jobType === JobType.NEW_PART) {
    const selectedPart = partTypes.find((part) => part.type === typeOfPart);
    if (selectedPart) {
      const sides = createSidesFromPartType(selectedPart);
      replace(sides);
    }
  }
};

export const handleAddSide = (append: Function, appendDisplayed: Function) => {
  append(createNewSide());
  appendDisplayed(createNewSide());
};

export const handleRemoveSide = (sideNb: number, remove: Function, removeDisplayed: Function) => {
  remove(sideNb);
  removeDisplayed(sideNb);
};

export const handleSwitchChange = (
  sideNb: number,
  name: string,
  checked: boolean,
  setValue: Function,
  trigger: Function
) => {
  setValue(`sides.${sideNb}.${name}`, checked);
  setValue(`displayed_sides.${sideNb}.${name}`, checked);
  trigger([`sides.${sideNb}.${name}`, `displayed_sides.${sideNb}.${name}`]);
};

export const handleCopySide = (
  sideNb: number,
  getValues: Function,
  append: Function,
  appendDisplayed: Function,
  update: Function,
  updateDisplayed: Function,
  jobType: string,
  typeOfPart: string
) => {
  const type = partTypes.find((partType) => partType.type === typeOfPart);
  const actualLength = getValues('sides')?.length;
  const copiedSide = getValues(`sides.${sideNb}`);
  if (actualLength < type?.nbSides || !type?.nbSides || jobType === JobType.REPAIR) {
    append(copiedSide);
    appendDisplayed(copiedSide);
  } else {
    if (actualLength === type?.nbSides) {
      for (let i = sideNb + 1; i < actualLength; i++) {
        const currentSide = getValues(`sides.${i}`);
        if (currentSide?.end?.length) {
          update(i, { ...copiedSide, end: currentSide.end });
          updateDisplayed(i, { ...copiedSide, end: currentSide.end });
        } else {
          update(i, copiedSide);
          updateDisplayed(i, copiedSide);
        }
      }
    }
  }
};

export function getTitleContext(displayedSidesLength: number): string {
  return displayedSidesLength > 1 ? 'multiple' : 'one';
}
