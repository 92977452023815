export const PERMISSIONS = {
  MY_GAUGES_ALL: 'mygauges:all',
  MANAGE_LICENSEE_ALL: 'manage-licensee:all',
  MANAGE_BOOKING_ALL: 'manage-booking:all',
  CREATE_BOOKING_ALL: 'create-booking:all',
  SET_MODEL_ALL: 'set-model:all',
  SET_ALL: 'set:all',
  SHIPMENT_ALL: 'shipment:all',
  RETURN_BOOKINGS_ALL: 'return-bookings:all',
  MANAGE_USER_ALL: 'manage-user:all',
  MANAGE_MY_USERS_ALL: 'manage-my-users:all',
  INVOICES_ALL: 'invoices:all',
  ROYALTIES_JOB_ALL: 'royalties-job:all',
  DOCUMENTATION_ALL: 'documentation:all',
  DOCUMENTATION_READ: 'documentation:read',
};

export const ENTITY_PERMISSIONS = {
  ManageBooking: [PERMISSIONS.MANAGE_BOOKING_ALL],
  BookingDetails: [PERMISSIONS.MY_GAUGES_ALL],
  CreateBooking: [PERMISSIONS.CREATE_BOOKING_ALL],
  SetModel: [PERMISSIONS.SET_MODEL_ALL],
  Set: [PERMISSIONS.SET_ALL],
  MyGauges: [PERMISSIONS.MY_GAUGES_ALL],
  ManageShipments: [PERMISSIONS.SHIPMENT_ALL],
  ManageLicensee: [PERMISSIONS.MANAGE_LICENSEE_ALL],
  PrepareReturns: [PERMISSIONS.RETURN_BOOKINGS_ALL],
  ManageUser: [PERMISSIONS.MANAGE_USER_ALL],
  ManageMyUsers: [PERMISSIONS.MANAGE_MY_USERS_ALL],
  ManageInvoice: [PERMISSIONS.INVOICES_ALL],
  ManageCompanies: [PERMISSIONS.MANAGE_LICENSEE_ALL],
  CreateJob: [PERMISSIONS.ROYALTIES_JOB_ALL],
  ManageDocumentation: [PERMISSIONS.DOCUMENTATION_ALL],
  ManageDocumentationRead: [PERMISSIONS.DOCUMENTATION_READ],
};
