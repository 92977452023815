import { SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { AccountCircle, Autorenew, Close, Menu, SwitchAccount } from '@mui/icons-material';
import { DialogActions, Link as MuiLink, Stack, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import VamBlue from '../../../assets/logo-vam-blue.svg';
import { useAppSelector } from '../../../hooks';
import { useSelectRoleBasedAccessControl } from '../../../hooks/useSelectRoleBasedAccessControl';
import { selectUser } from '../../../services/store/userSlice';
import { $grey30, $grey50, $secondaryBlue } from '../../../styles/colors';
import { ENTITY_PERMISSIONS } from '../../../utils/constants/auth0-roles';
import { getLicenseeName } from '../../../utils/functions';
import { checkAuthorizationAccess } from '../../../utils/functions/helpers';
import { getMyVamName } from '../../../utils/functions/user';
import { VamDialog } from '../../shared';
import SideMenuApp from '../SideMenuApp/SideMenuApp';

export function Header(props) {
  const { t } = useTranslation('common');
  const userDetails = useAppSelector(selectUser);
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const { mypermissions } = useSelectRoleBasedAccessControl();
  const navigate = useNavigate();

  const sideMenuItems = [
    {
      label: t('sideMenu.vamEasyGauge'),
      subItems: [
        {
          label: t('sideMenu.bookGauge'),
          link: '/vam-easy-gauge/booking',
        },
        {
          label: t('sideMenu.manageBooking'),
          link: '/admin/vam-easy-gauge/manage-booking',
          permissions: ENTITY_PERMISSIONS.ManageBooking,
        },
        {
          label: t('sideMenu.globalSearch'),
          link: '/admin/vam-easy-gauge/global-search',
        },
        {
          label: t('sideMenu.dispatchSummary'),
          link: '/admin/vam-easy-gauge/dispatch',
        },
        {
          label: t('sideMenu.setModelsManagement'),
          link: '/admin/vam-easy-gauge/set-model/set-models-management',
        },
        {
          label: t('sideMenu.setsManagement'),
          link: '/admin/vam-easy-gauge/set-creation/sets-management',
        },
        {
          label: t('sideMenu.myUsers'),
          link: '/vam-easy-gauge/licensee-users',
          permissions: ENTITY_PERMISSIONS.CreateBooking,
        },
        {
          label: t('sideMenu.myGauges'),
          link: '/vam-easy-gauge/my-gauges',
        },
        {
          label: t('sideMenu.returnMyBookings'),
          link: '/vam-easy-gauge/return-bookings',
        },
        {
          label: t('sideMenu.manageMyUsers'),
          link: '/vam-easy-gauge/manage-my-users',
        },
        {
          label: t('sideMenu.licenseeManageDocumentation'),
          link: '/vam-easy-gauge/licensee-documentation',
        },
      ].filter((item) => checkAuthorizationAccess(mypermissions, item?.permissions)),
    },
  ];

  /* istanbul ignore next */
  const handleConfirmSwitchLicensee = () => {
    navigate('/');
    window.location.reload();
  };

  async function redirectToUserDetails() {
    navigate('/vam-easy-gauge/licensee-users/manage-user');
  }

  return (
    <Box sx={{ flexGrow: 1, zIndex: (theme) => theme.zIndex.drawer + 1, position: 'sticky' }}>
      <VamDialog
        title={t('switchLicenseeTitle')}
        headerIcon={<Autorenew fontSize="large" color="secondary" />}
        isOpen={openConfirmDialog}
        handleClose={/* istanbul ignore next */ () => setOpenConfirmDialog(false)}
        withCloseButton
        fullWidth
        maxWidth="sm"
        dialogActions={
          <DialogActions className="!tw-justify-center">
            <SimpleButton
              data-testid="switch-licensee-dialog-cancel-btn"
              variant="outlined"
              size="small"
              onClick={() => setOpenConfirmDialog(false)}
            >
              {t('confirmDialog.cancel')}
            </SimpleButton>
            <SimpleButton
              size="small"
              data-testid="switch-licensee-dialog-confirm-btn"
              variant="cta"
              onClick={handleConfirmSwitchLicensee}
            >
              {t('confirmDialog.confirm')}
            </SimpleButton>
          </DialogActions>
        }
      >
        <div className="tw-flex tw-p-8 tw-text-center">{t('switchLicenseeDialogQst')}</div>
      </VamDialog>
      <AppBar position="static" className="bg-color-white">
        <Toolbar>
          <IconButton
            onClick={() => setOpenSideMenu(!openSideMenu)}
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {openSideMenu ? <Close /> : <Menu />}
          </IconButton>
          <SideMenuApp
            needSpaceForAppBar
            items={sideMenuItems}
            open={openSideMenu}
            setOpen={setOpenSideMenu}
          />
          <Link to="/">
            <img src={VamBlue} alt="logo-vam" data-testid="logo-vam-blue" />
          </Link>
          <Box sx={{ flexGrow: 1 }} />

          <Box className="tw-mr-8">
            <Stack direction="column">
              <Typo variant="body1" color={$secondaryBlue}>
                <MuiLink
                  component="button"
                  data-testid="switch-licensee-cta-btn"
                  underline="none"
                  hidden={!userDetails.hasManyLicensees}
                  onClick={() => {
                    setOpenConfirmDialog(true);
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <SwitchAccount className="tw-pr-2" /> {t('switchLicensee')}
                  </Stack>
                </MuiLink>
              </Typo>
              <Stack direction="row" spacing={1}>
                <Typo component="div" variant="body1" color={$grey30}>
                  {t('connectedAs')}
                </Typo>
                <Typo component="div" variant="body1" color={$grey50}>
                  {getLicenseeName(userDetails.licenseeNumber, userDetails.licenseeName)}
                </Typo>
              </Stack>
            </Stack>
          </Box>
          <Box className="tw-flex" sx={{ cursor: 'pointer' }} onClick={redirectToUserDetails}>
            <AccountCircle color="primary" />
            <Typo sx={{ marginLeft: '4px' }} variant="body1" color={$secondaryBlue}>
              {getMyVamName(userDetails)}
            </Typo>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
