/* istanbul ignore file */
import { Suspense } from 'react';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { Dispatch } from '../components/booking/dispatch-summary/Dispatch';
import { DispatchSummary } from '../components/booking/dispatch-summary/DispatchSummary';
import { GlobalSearch } from '../components/booking/global-search/GlobalSearch';
import { MakeReturn } from '../components/booking/make-return/MakeReturn';
import { BookingDetail } from '../components/booking/manage-booking/BookingDetail';
import { CurrentRentals } from '../components/booking/manage-booking/CurrentRentals';
import { ManageBooking } from '../components/booking/manage-booking/ManageBooking';
import { MyGauges } from '../components/booking/my-gauges/MyGauges';
import { ConfirmReturn } from '../components/booking/shipment/ConfirmReturn/ConfirmReturn';
import { ManageShipments } from '../components/booking/shipment/ManageShipments';
import CaseCreation from '../components/case/CaseCreation';
import UpdateCase from '../components/case/UpdateCase';
import GaugeDepotCreation from '../components/gauge-depot/GaugeDepotCreation';
import UpdateGaugeDepot from '../components/gauge-depot/UpdateGaugeDepot';
import { Auth0Callback } from '../components/guards/Auth0Callback';
import { AuthorizedRoleGuard } from '../components/guards/AuthorizedRoleGuard';
import { ManageInvoice } from '../components/invoice/ManageInvoice';
import { NotAuthorized } from '../components/layout/NotAuthorized/NotAuthorized';
import { SetsManagement } from '../components/set/sets-management/SetsManagement';
import UpdateSet from '../components/set/sets-management/UpdateSet';
import { SetModelsManagement } from '../components/setModel/set-models-management/SetModelsManagement';
import { UpdateSetModel } from '../components/setModel/set-models-management/UpdateSetModel';
import { Error } from '../components/shared';
import { PageLoader } from '../components/shared/PageLoader/PageLoader';
import ManageSingleUser from '../components/user/ManageSingleUser';
import DashboardAdmin from '../pages/dashboard-admin/DashboardAdmin';
import InvoiceDetails from '../pages/invoicing/InvoiceDetails';
import { ReturnBookings } from '../pages/return-bookings/ReturnBookings';
import CreateJob from '../pages/royalties/CreateJob';
import { getCase } from '../services/api/case-api';
import { getLicenseeDetails } from '../services/api/context-api';
import { getInvoiceDetails } from '../services/api/invoice-api';
import { getSetDetails } from '../services/api/set-creation-api';
import { checkSetModelLinked, getSetModelManagementDetails } from '../services/api/set-model-api';
import { getBookingDetail, getPickupAddress } from '../services/api/veg-api';
import { ENTITY_PERMISSIONS } from '../utils/constants/auth0-roles';
import { strToNumber } from '../utils/functions';
import { AuthGuard } from './../components/guards/AuthGuard';
import { LayoutOutlet } from './../components/layout/LayoutOutlet/LayoutOutlet';
import { NotFound } from './../components/layout/NotFound/NotFound';
import { Home } from './../pages/home/Home';
import {
  LazyBookingGauges,
  LazyCaseListing,
  LazyCreatePublication,
  LazyGaugeDepotListing,
  LazyInvoiceListing,
  LazyLicenseeCreation,
  LazyLicenseeListing,
  LazyLicenseeManageDocumentation,
  LazyLicenseeUpdate,
  LazyManageCompanies,
  LazyManageDocumentation,
  LazyManageUsers,
  LazyMyUsersListing,
  LazyReplacementBooking,
  LazySetCreation,
  LazySetModel,
  LazyUserCreation,
  LazyUserListing,
} from './lazy.loading';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      <Route path="not-authorized" element={<NotAuthorized />} />
      <Route path="callback" element={<Auth0Callback />} />
      <Route path="vam-easy-gauge" element={<AuthGuard component={LayoutOutlet} />}>
        <Route
          path="booking"
          index
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.CreateBooking}
              element={
                <Suspense fallback={<PageLoader />}>
                  <LazyBookingGauges />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="booking/:bookingId"
          loader={({ params }) => {
            return getBookingDetail(params.bookingId);
          }}
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.BookingDetails}
              element={<BookingDetail />}
            />
          }
          errorElement={<Error />}
          // action : todo for update
        />
        <Route
          path="replacement-booking"
          index
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.CreateBooking}
              element={
                <Suspense fallback={<PageLoader />}>
                  <LazyReplacementBooking />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="my-gauges"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.MyGauges}
              element={<MyGauges />}
            />
          }
        />
        <Route path="return-bookings">
          <Route
            path=""
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.MyGauges}
                element={<ReturnBookings />}
              />
            }
          />
          <Route
            path="make-return"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.PrepareReturns}
                element={<MakeReturn />}
              />
            }
          />
        </Route>
        <Route path="licensee-users">
          <Route
            path=""
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageMyUsers}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyMyUsersListing />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="manage-user"
            element={
              <AuthorizedRoleGuard requiredPermissions={[]} element={<ManageSingleUser />} />
            }
          />
        </Route>
        <Route
          path="manage-my-users"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.ManageUser}
              element={
                <Suspense fallback={<PageLoader />}>
                  <LazyManageUsers />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="royalties"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.CreateJob}
              element={<CreateJob />}
            />
          }
        />

        <Route
          path="licensee-documentation"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.ManageDocumentationRead}
              element={
                <Suspense fallback={<PageLoader />}>
                  <LazyLicenseeManageDocumentation />
                </Suspense>
              }
            />
          }
        />
      </Route>
      {/* ADMIN ROUTES */}
      <Route path="admin/vam-easy-gauge" element={<AuthGuard component={LayoutOutlet} />}>
        <Route path="dashboard" element={<DashboardAdmin />} />
        <Route
          path="manage-companies"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.ManageCompanies}
              element={
                <Suspense fallback={<PageLoader />}>
                  <LazyManageCompanies />
                </Suspense>
              }
            />
          }
        />
        <Route
          path="manage-booking"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.ManageBooking}
              element={<ManageBooking />}
            />
          }
        />
        <Route path="dispatch">
          <Route path="" element={<DispatchSummary />} />
          <Route path="dispatch-detail" element={<Dispatch />} />
        </Route>
        <Route path="manage-shipments">
          <Route
            path=""
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageShipments}
                element={<ManageShipments />}
              />
            }
          />
          <Route
            path="confirm-return"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageShipments}
                element={<ConfirmReturn />}
              />
            }
          />
        </Route>

        <Route path="current-rentals" element={<CurrentRentals />} />
        <Route path="global-search" element={<GlobalSearch />} />
        <Route path="licensee">
          <Route
            path="licensee-listing"
            element={
              <Suspense fallback={<PageLoader />}>
                <LazyLicenseeListing />
              </Suspense>
            }
          />
          <Route
            path="create"
            element={
              <Suspense fallback={<PageLoader />}>
                <LazyLicenseeCreation />
              </Suspense>
            }
          />
          <Route
            path="licensee-listing/:licenseeNumber"
            loader={({ params }) => {
              return getLicenseeDetails(parseInt(params.licenseeNumber, 10));
            }}
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageLicensee}
                element={<LazyLicenseeUpdate />}
              />
            }
            errorElement={<Error />}
          />
        </Route>
        <Route path="user">
          <Route
            path="user-listing"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageUser}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyUserListing />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="create"
            element={
              <Suspense fallback={<PageLoader />}>
                <LazyUserCreation />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="booking/:bookingId"
          loader={({ params }) => {
            return getBookingDetail(params.bookingId);
          }}
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.BookingDetails}
              element={<BookingDetail />}
            />
          }
          errorElement={<Error />}
          // action : todo for update
        />
        <Route path="set-model">
          <Route
            path="create"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.SetModel}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazySetModel />
                  </Suspense>
                }
              />
            }
          />

          <Route path="set-models-management" element={<SetModelsManagement />} />
          <Route
            path="set-models-management/:setModelId"
            loader={({ params }) => {
              return Promise.all([
                getSetModelManagementDetails(params.setModelId),
                checkSetModelLinked(params.setModelId),
              ]);
            }}
            element={<UpdateSetModel />}
            errorElement={<Error />}
          />
        </Route>

        <Route path="set-creation">
          <Route
            path="create"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.Set}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazySetCreation />
                  </Suspense>
                }
              />
            }
          />
          <Route path="sets-management" element={<SetsManagement />} />
          <Route
            path="sets-management/:setId"
            loader={({ params }) => {
              return getSetDetails(params.setId);
            }}
            element={<UpdateSet />}
            errorElement={<Error />}
          />
        </Route>
        <Route
          path="manage-invoices"
          element={
            <AuthorizedRoleGuard
              requiredPermissions={ENTITY_PERMISSIONS.ManageInvoice}
              element={<ManageInvoice />}
            />
          }
        />
        <Route path="invoices">
          <Route
            path=""
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageInvoice}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyInvoiceListing />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path=":invoice_id"
            loader={({ params }) => {
              return getInvoiceDetails(strToNumber(params.invoice_id) as number);
            }}
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageInvoice}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <InvoiceDetails />
                  </Suspense>
                }
              />
            }
            errorElement={<Error />}
          />
        </Route>

        <Route path="case">
          <Route
            path="cases-listing"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.SetModel}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyCaseListing />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="create"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.SetModel}
                element={<CaseCreation />}
              />
            }
          />
          <Route
            path="cases-listing/:caseId"
            loader={({ params }) => {
              return getCase(params.caseId);
            }}
            element={<UpdateCase />}
            errorElement={<Error />}
          />
        </Route>

        <Route path="depot">
          <Route
            path="depot-listing"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageBooking}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyGaugeDepotListing />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="create"
            element={
              <AuthorizedRoleGuard
                requiredPermissions={ENTITY_PERMISSIONS.ManageBooking}
                element={<GaugeDepotCreation />}
              />
            }
          />
          <Route
            path="depot-listing/:pickupAddressId"
            loader={({ params }) => {
              return getPickupAddress(strToNumber(params.pickupAddressId) as number);
            }}
            element={<UpdateGaugeDepot />}
            errorElement={<Error />}
          />
        </Route>
        <Route path="documentation">
          <Route
            index
            element={
              <AuthorizedRoleGuard
                // todo which permission
                requiredPermissions={ENTITY_PERMISSIONS.ManageDocumentation}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyManageDocumentation />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="create"
            element={
              <AuthorizedRoleGuard
                // todo which permission
                requiredPermissions={ENTITY_PERMISSIONS.ManageCompanies}
                element={
                  <Suspense fallback={<PageLoader />}>
                    <LazyCreatePublication />
                  </Suspense>
                }
              />
            }
          />
        </Route>
      </Route>
    </>
  )
);

export default Router;
